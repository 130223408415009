<template>
  <div class="TOS">
    <div class="topbar">
      <img @click="$router.go(-1)" :src='require("@/assets/imgs/back.png")' style="width: .8rem;height: .6rem;    margin-left: .64rem;"/>
      <div class="head-title">{{ $t("服務條款") }}</div>
    </div>
    <div class="content">
      <div>
        <ul>
          <li>
            <h3>{{ $t("第1條 服務條款") }}</h3>
            <p>
              {{
                $t(
                  "1、G18對本站進行服務條款的制定，並遵從此條款向用戶提供營運服務（以下稱為本服務。）"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、本站除了服務條款之外，也依個別服務訂立其他條款（以下稱為個別條款。）當服務條款與個別條款內容不一致時，將會優先適用個別條款的內容。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、除了其他特別說明的情況外，個別條款、本服務的幫助頁、導引頁面、其他關於本服務的說明頁面等，這些都將視為服務條款的一部分，並合稱服務條款。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、“用戶”或“您”，又稱“會員”，即指使用和享有G18中遊戲産品及服務的自然人，是本服務條款的一方合約當事人。"
                )
              }}
            </p>
            <p>
              {{ $t("5、您將會視為同意服務條款後，進行本服務的使用。") }}
            </p>
            <p>
              {{
                $t(
                  "6、您確認您在訪問本網站的司法管轄區內的年齡至少為18歲，並且完全有能力履行及遵守所述的條款。如果您未滿18歲，您應立即停止使用本服務。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "7、您同意並表示您訪問本服務的管轄區不禁止接收或查看色情內容。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "8、在您開始使用本服務之前，請仔細閱讀此條款。當您使用本服務時，即表示您接受並同意遵守這些條款和我們的隱私權保護政策，如果您不同意這些服務條款或隱私權保護政策，您應立即停止使用本服務。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第2條 條款的變更") }}</h3>
            <p>
              {{
                $t(
                  "1、本站判斷為必要的狀況，得不事先通知用戶，於任何時候更改服務條款。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、變更後的服務條款將會於本是網站上公布時生效，變更後使用本服務時將會視同您同意變更後的條款。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第3條 通知用戶的方法・用戶的聯繫方法") }}</h3>
            <p>
              {{
                $t(
                  "1、本站對於用戶的聯繫將會以電子郵件、網頁的訊息揭示及其他本站判斷為適宜的方法。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、通知採用電子郵件進行時，本站向用戶寄出郵件的時間點，將會視為用戶已收到該通知。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、通知採用網頁公開時，通知於網頁上刊載的時間點，將會視為用戶已收到該通知。"
                )
              }}
            </p>
            <p>
              {{
                $t("4、用戶對本站的聯絡方式，將需要採用本站指定的方式進行。")
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第4條 用戶註冊") }}</h3>
            <p>
              {{
                $t(
                  "1、希望使用本服務的用戶，將需要依本站指定的方式進行用戶註冊。"
                )
              }}
            </p>
            <p>
              {{ $t("2、本服務內的成人服務，僅限於18歲以上的用戶使用。") }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第5條 用戶的帳號・密碼") }}</h3>
            <p>
              {{
                $t(
                  "1、用戶自身的帳號及密碼，用戶將需要自負管理的責任。第三方不正當的使用等情況本站概不負責。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、用戶不得將自己的帳號及密碼提供給第三方使用、轉移、或是借出。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、本站收到用戶的帳號密碼，並且使用本服務時，將會視為該帳號及密碼的用戶進行的使用，後續效果的責任歸屬亦將屬於該用戶。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、用戶若有自己的帳號密碼被第三方得知或被第三方使用的疑慮，應告知本站並遵從本站的指示。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第6條 手機ID認證") }}</h3>
            <p>
              {{
                $t(
                  "1、使用手機ID認證進行註冊的用戶，用戶將需要自負手機ID等資料的嚴加管理責任。第三方不正當使用等情況本站概不負責。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、本站在收到註冊的手機ID，並使用本站服務時，將視為該手機ID的用戶進行使用，後續效果的責任歸屬亦將屬於該用戶。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、用戶若遺失手機ID的註冊手機，或是有被第三方使用的疑慮，應告知本站並遵從本站的指示。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第7條 轉移等的禁止") }}</h3>
            <p>
              {{
                $t(
                  "本站的用戶資格，或是基於用戶資格使用本服務的權利及義務等，將不得轉移於第三方、販賣給第三方、借出於第三方、讓第三方繼承、允許第三方使用或是讓第三方進行其他處分。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第8條 註冊情報的變更") }}</h3>
            <p>
              {{ $t("若是因疏於變更登錄資料造成的損害等，本站概不負責。") }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第9條 註銷帳號") }}</h3>
            <p>
              {{
                $t(
                  "1、用戶註銷帳號後將喪失用戶資格，無法再使用本服務。另外，註銷帳號後，喪失的用戶資格將無法復原。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、用戶若是對本站還有金錢債務或是其他債務，不會因註銷帳號而免除。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、用戶保有的本站發放之未使用的預付手段，以及本站還可以使用的虛擬貨幣，在完成帳號註銷時將全部失效，本站在法令規定的狀況除外，將不須進行退款等措施。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第10條 服務使用環境") }}</h3>
            <p>
              {{
                $t(
                  "1、用戶使用本服務的費用，以及本服務內商品的購物款項、運費及其他用戶應負擔的費用（以下將稱為對價。）須藉由本站指定的付款方式進行支付。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、用戶可藉由本站指定的信用卡業者、電子貨幣業者、手機通訊業者等（以下稱為支付站。）提供的支付手段進行對價的支付。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、藉由代理伺服器、VPN伺服器等使用本服務時，有時會有無法正常使用本服務的狀況發生。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第11條 用戶的自身責任") }}</h3>
            <p>
              {{
                $t(
                  "1、用戶因使用本服務與第三方發生糾紛時，用戶將需要承擔其責任及費用解決紛爭。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、用戶因使用本服務造成第三方損害時，用戶將需要承擔其責任及費用賠償損害。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第12條 付款") }}</h3>
            <p>
              {{
                $t(
                  "1、G18 站（以下稱為本站。）進行服務條款的制定，並遵從此條款向用戶提供營運服務（以下稱為本服務。）"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、本站除了服務條款之外，也依個別服務訂立其他條款（以下稱為個別條款。）當服務條款與個別條款內容不一致時，將會優先適用個別條款的內容。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、於前項的狀況，用戶將需要遵從支付站訂立的條款，與支付站締結契約。用戶與支付站發生糾紛的情況，用戶須負責解決該糾紛，本站概不負責。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、支付的手續費（限於本站指定的費用。）將需要由用戶自行負擔。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "5、用戶對於本站不管有任何理由，皆不可要求已付款的對價進行退款。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第13條 禁止行為") }}</h3>
            <p>
              {{ $t("1、本站在用戶使用本站服務時，禁止下列的各項行為。") }}
            </p>
            <p>
              {{
                $t(
                  "（1）侵害本站或是第三方的著作權、商標權、創作權、特許權、實用新案權及其他智慧財產權的行為"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "（2）本站或是第三方的財產權、肖像權、出版權、人格權、名譽權、隱私權等等的侵害行為"
                )
              }}
            </p>
            <p>
              {{ $t("（3）違反公序良俗的行為") }}
            </p>
            <p>
              {{ $t("（4）違反法令的行為") }}
            </p>
            <p>
              {{ $t("（5）犯罪行為、結合或助長犯罪的行為") }}
            </p>
            <p>
              {{ $t("（6）違反兒童色情、色情、猥褻物等相關法令的行為") }}
            </p>
            <p>
              {{ $t("（7）刊登虛偽資訊的行為") }}
            </p>

            <p>
              {{ $t("（8）會帶給本站伺服器過度負擔的行為") }}
            </p>
            <p>
              {{ $t("（9）妨礙本服務營運的行為") }}
            </p>
            <p>
              {{ $t("（10）違背本服務之提供目的使用本服務的行為") }}
            </p>
            <p>
              {{
                $t(
                  "（11）將本服務提供內容中實行的保護、DRM等技術保護手段進行消除、迴避，或是無效化等行為"
                )
              }}
            </p>
            <p>
              {{ $t("（12）損害本站或是第三方利益之行為") }}
            </p>

            <p>
              {{
                $t("（13）對本站或是第三方進行誹謗中傷、脅迫、或引起厭惡的行為")
              }}
            </p>
            <p>
              {{ $t("（14）造成或助長歧視第三方的行為") }}
            </p>
            <p>
              {{
                $t(
                  "（15）未經第三方同意的情況下收集或公開其個人情報或隱私的行為"
                )
              }}
            </p>
            <p>
              {{
                $t("（16）選舉的事前造勢、選舉相關的行為，以及違反選舉法的行為")
              }}
            </p>
            <p>
              {{ $t("（17）以宣傳用戶或是第三方的營業來使用本服務的行為") }}
            </p>

            <p>
              {{ $t("（18）以招募老鼠會、傳銷成員為目的使用本服務的行為") }}
            </p>
            <p>
              {{ $t("（19）上傳本服務外其他連結的行為") }}
            </p>
            <p>
              {{ $t("（20）妨礙本站業務的行為") }}
            </p>
            <p>
              {{ $t("（21）將虛假的情報在本服務登錄的行為") }}
            </p>
            <p>
              {{ $t("（22）違反本站其他或個別條款的行為") }}
            </p>
            <p>
              {{ $t("（23）其他本站判斷為不適當的行為") }}
            </p>
            <p>
              {{
                $t(
                  "2、關於是否屬於前項描述禁止行為的判斷，將會由本站進行裁量，且本站不須負判斷的說明責任。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、本站在判斷用戶行為違反第一項提到禁止行為的情況，得不經事前通知進行下列的任意或是全部的處置。"
                )
              }}
            </p>
            <p>
              {{ $t("（1）限制本服務的使用") }}
            </p>
            <p>
              {{ $t("（2）解除用戶登錄及註冊") }}
            </p>
            <p>
              {{ $t("（3）其他本站判斷必要的行為") }}
            </p>
            <p>
              {{ $t("4、因前項措施造成用戶損害的情況本站概不負責") }}
            </p>
            <p>
              {{
                $t(
                  "5、本站在用戶違反服務條款造成的一切損害、損失、費用（含訴訟費用及律師費用。）皆可進行賠償要求。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第14條 服務利用限制") }}</h3>
            <p>
              {{
                $t(
                  "1、本站在下列各項情形發生時皆可對於用戶進行本服務使用的限制。"
                )
              }}
            </p>
            <p>
              {{ $t("（1）用戶的帳戶ID或密碼有第三方使用的疑慮") }}
            </p>
            <p>
              {{ $t("（2）登錄情報中含有虛假情報的疑慮") }}
            </p>
            <p>
              {{ $t("（3）對價及費用等的支付有遲疑可能性的疑慮") }}
            </p>
            <p>
              {{ $t("（4）本站聯繫不到用戶的情況") }}
            </p>
            <p>
              {{ $t("（5）其他本站判斷必要的情況") }}
            </p>
            <p>
              {{ $t("2、因前項措施造成用戶損害的情況本站概不負責。") }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第15條 用戶資格的解除") }}</h3>
            <p>
              {{
                $t("1、本站在下列事項發生時，得不進行通知解除用戶登錄及註冊。")
              }}
            </p>
            <p>
              {{ $t("（1）登錄資料中含有虛假的資訊") }}
            </p>
            <p>
              {{ $t("（2）對價等的支付有遲延的情況") }}
            </p>
            <p>
              {{ $t("（3）用戶的信用狀態惡化，難以繼續進行對價支付的情況") }}
            </p>
            <p>
              {{ $t("（4）過去曾遭到本站進行註銷帳號處分的情況") }}
            </p>
            <p>
              {{
                $t(
                  "（5）用戶的相關人士聯繫表明用戶本人的死亡，或是本站確認用戶本人死亡事實的情況"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "（6）本站判斷為反社會勢力（暴力團、暴力團構成成員、準構成成員、暴力團關係企業等或是其他相關成員）的情況"
                )
              }}
            </p>
            <p>
              {{ $t("（7）申請破產手續或是開始破產手續、民事再生手續的情況") }}
            </p>
            <p>
              {{ $t("（8）對於本站的邀請不誠實處理的情況") }}
            </p>
            <p>
              {{ $t("（9）其他本站判斷不適當的情況") }}
            </p>
            <p>
              {{ $t("2、因前項措施造成用戶損害的情況本站概不負責。") }}
            </p>
            <p>
              {{
                $t(
                  "3、因第一項的措施被註銷用戶資格的用戶，將會喪失至註銷用戶資格期間的利益，並需要立即償還對本站的債務。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第16條 服務提供的暫時中斷") }}</h3>
            <p>
              {{ $t("1、本站在下列的各項情況，得暫時中斷本服務的提供。") }}
            </p>
            <p>
              {{ $t("（1）本服務的維護、檢查等情況") }}
            </p>
            <p>
              {{ $t("（2）發生火災、停電等事故的情況") }}
            </p>
            <p>
              {{ $t("（3）自然災害等非常態情形發生的情況") }}
            </p>
            <p>
              {{ $t("（4）系統或網路發生干擾的情況") }}
            </p>
            <p>
              {{ $t("（5）本站業務上產生不得不暫時停止服務的事由之情況") }}
            </p>
            <p>
              {{ $t("（6）其他本站判斷為必要的情況") }}
            </p>
            <p>
              {{
                $t(
                  "2、因前項措施造成用戶無法利用本服務進而造成的損害情況本站概不負責。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第17條 服務的變更、追加、廢止") }}</h3>
            <p>
              {{
                $t(
                  "1、本站的服務將不進行任何符合用戶的特定目的或是用戶期待的品質、價值等進行任何保證。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、本站得隨時變更、追加或廢止所有或一部分的服務內容，且不須負任何責任。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、本站在第一項規定的進行本服務全部或重要的一部分變更、追加或是廢止時，將會對用戶進行相當期間的通知。但緊急或是不得不為的情況則不包含在內。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第18條 免責") }}</h3>
            <p>
              {{
                $t(
                  "1、本站的服務將不進行任何符合用戶的特定目的或是用戶期待的品質、價值等進行任何保證。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、本站對於本服務的完整性、正確性、確實性、信賴性、有用性等皆不進行任何保障。"
                )
              }}
            </p>
            <p>
              {{ $t("3、本站對使用本服務時，產生的一切損害等皆不須負責。") }}
            </p>
            <p>
              {{
                $t(
                  "4、本站在用戶無法使用本服務時，產生的一切損害等皆不須負責。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "5、本站管理的網站中被設置網站連結的情況，因第三方管理的網站非本站管轄範圍，本站將不對該連結及網站負任何責任。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第19條 知識財產權") }}</h3>
            <p>
              {{
                $t(
                  "1、本服務提供的影像、聲音、程式、文字、畫像、圖片、設計、商標、徽標、以及其他一切的情報（以下稱為本內容。）的版權、鄰接權、商標權、設計權等知識財產權，屬於本站或授權給本站的第三方。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、用戶不得進行侵害本站或是第三方的，關於提供內容權利之行為。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第20條 業務轉移") }}</h3>
            <p>
              {{
                $t(
                  "本站將本服務的關係事業轉移於第三方的情況，將會隨著業務轉移，將本服務的營運者地位、依據服務條款的權利及義務以及登錄情報等全數轉移給被轉移的第三方。用戶亦同意用戶的身分、基於服務條款的權利及義務、登錄情報；以及其他情報的轉移。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第21條 外包") }}</h3>
            <p>
              {{
                $t(
                  "1、本站可以將本服務業務的全部或一部分委託給第三方（含外國的第三方。）用戶亦同意此項。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、在前項情況及本站判斷為必要的情況，得將個人情報提供給第三方。用戶亦同意此項。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第22條 債權轉移") }}</h3>
            <p>
              {{
                $t(
                  "1、用戶對於用戶在本站的債務得轉移給第三方（以下稱為債權接受人。）是同意並無異議的。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、在前項的狀況，關於該債權轉移的請求及回收，用戶對於該債權接受人要求提供用戶姓名、住址、電話號碼及行使債權所需要的一切資訊皆同意且無異議。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、在第1項的情況，本站及債權接受人將會省略對各用戶的通知及轉移同意請求。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第23條 損害賠償責任") }}</h3>
            <p>
              {{
                $t(
                  "用戶在使用本服務等相關行為損害本站利益時，須負起賠償該損害的責任。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第24條 適用法律和專屬管轄法院") }}</h3>
            <p>
              {{ $t("1、服務條款應按照台灣法律解釋。") }}
            </p>
            <p>
              {{
                $t(
                  "2、本站與用戶間發生本服務或服務條款相關的紛爭時，處理上將會以台北地方法院為初審專屬管轄法院。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第25條 個人信息的處理") }}</h3>
            <p>
              {{
                $t(
                  "本站對於用戶個人資訊的處理將會依據本站訂立的「隱私權保護政策」進行。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第26條 可分性") }}</h3>
            <p>
              {{
                $t(
                  "服務條款的任一項或是一部分，若是因消費者契約法或是其他法令，被判斷為無效或是無法執行時，其他各項服務條款的規定將依然保持有效。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第27條 與外部服務合作") }}</h3>
            <p>
              {{
                $t(
                  "1、用戶得使用第三方營運（本站指定的服務，以下稱為外部服務。另外，營運外部服務的站，以下稱為外部服務營運站。）的服務帳號，依第4條進行用戶註冊。該註冊將需要依本站訂立的手續（以下稱為合作手續。）進行。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "2、用戶在進行合作手續時，同意將在外部服務登錄的用戶相關情報（以下稱為外部服務情報。）提供給本站，並於用戶註冊的階段顯示。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "3、本站收到的，含外部服務資訊在內的個人資訊，將會依照本站的訂立的「隱私權保護政策」進行處理。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、在外部服務的帳戶註冊、使用（含留言或張貼等行為）用戶皆須要依照外部營運站訂立的各項條款進行。"
                )
              }}
            </p>
            <p>
              {{
                $t("5、用戶對於在外部服務進行的帳號登錄、管理皆須要自行負責。")
              }}
            </p>
            <p>
              {{
                $t(
                  "6、用戶的外部服務帳號及密碼即使因管理不足、使用疏失、第三方的使用，造成用戶或第三方的損害，用戶應自行負責，本站將不會承擔任何責任。另外，因這些事由造成本站所害的情況，用戶須負起一切責任，進行本站遭受損害的賠償。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "7、使用外部服務時，用戶須對使用該服務自行負責，本站對於使用該外部服務造成的損害，該外部服務的營運與用戶間產生的糾紛及其他關於該外部服務一切的事項皆不須負責。"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第28條 費用及支付") }}</h3>
            <p>
              {{
                $t(
                  "1、關於G18的使用費用以及支付方式，除本條款之外，還應以其他顯示於G18網站上的由G18所制定的付費標準爲準。"
                )
              }}
            </p>
            <p>
              {{
                $t("2、關於購買點數的手續費、稅金和初期費用等由用戶自行承擔。")
              }}
            </p>
            <p>
              {{
                $t(
                  "3、關於點數的購買，如果G18在指定的日期內未能確認伴隨開始使用以及繼續使用服務而發生合同更新所需費用的付款時，將認為用戶無更新繼續使用之意，並且終止合同。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、即使G18未能從信用卡站或其代理店收到使用費用時，用戶須同意承擔對G18全額支付G18所要求的金額的義務。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "5、允許使用用戶所指定的信用卡付款（即G18接受信用卡站的墊付）的許可，不僅指此次許可的付款，而視為只要在信用卡有效期內，允許使用此信用卡支付與G18所訂立的付費服務合同期間所有的費用（到用戶取消G18付費服務、解除合同、G18完成付費服務的終止手續為止），且不同意此條款的用戶不可使用信用卡付款。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "6、G18可在不得到用戶許可的情況下修改付費標準、付費服務內容等，且視為用戶對此表示同意。另外，修改後的付費標準將於付費服務合同更新時開始適用。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "7、如果用戶在超過規定的日期未能償還根據本條款的規定以及各項服務中對G18的債務時，須向G18支付自期限日的第二天起至償還日期間的由G18所規定的遲延賠償金。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "8、僅可以G18所制定的方法進行付款，因以G18所指定的支付方法之外的方法進行付款或有其他用戶的原因所造成損失G18不承擔任何責任。"
                )
              }}
            </p>
            <p>
              {{ $t("9、用戶須同意不可更改付費服務的合約期間。") }}
            </p>
            <p>
              {{
                $t(
                  "10、無論因為何種原因，點數購入後或是購買付費會員後皆不可要求退款。"
                )
              }}
            </p>
            <p>
              {{
                $t("11、當收到來自信用卡站的退款申請時,將停止該帳號的使用。")
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第29條 關於點數") }}</h3>
            <p>
              {{
                $t(
                  "來源於附加值增值系統的G18點數（以下稱為“點數”），是用戶可以享受G18所規定的附加值的象徵。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "1、支付G18網站其他服務條款所允許使用的服務的使用費用時，可使用點數。"
                )
              }}
            </p>
            <p>
              {{ $t("2、點數僅在G18ID的使用期間內有效。") }}
            </p>
            <p>
              {{
                $t(
                  "3、點數無法保證將來也可使用服務或優惠得權利，由於服務或優惠內容的變更，又或是系統的變更等導致點數的變更或廢除時，G18將無法保證用戶所持有的點數可繼續使用。"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、G18所提供用戶的點數使用情況（餘額、使用時間、使用服務、優惠等的使用情況）的顯示，將無法證明用戶的服務使用情況，或可否利用優惠。"
                )
              }}
            </p>
            <p>
              {{ $t("5、合作夥伴所提供的優惠，是根據其所制定的條件而提供。") }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第30條 點數使用相關禁止事項") }}</h3>
            <p>
              {{
                $t(
                  "對於違反以下禁止事項的用戶,G18將停止該帳號的使用、點數視為無效等一切處置。同時因此不當行為給G18或第三方造成的損失由該用戶賠償。G18嚴禁以下行為或類似以下行為:"
                )
              }}
            </p>
            <p>
              {{ $t("1、違反或涉嫌違反本網站任何條款中禁止事項所規定的行為") }}
            </p>
            <p>
              {{ $t("2、G18所制定的方法以外不正當取得點數之行為") }}
            </p>
            <p>
              {{
                $t(
                  "3、G18所制定的方法以外將點數轉讓、分享、借貸、贈與、合併於他人或是多個不同的ID間之行為"
                )
              }}
            </p>
            <p>
              {{
                $t(
                  "4、G18所指定的方式以外從他人獲取點數,或透過他人購入點數的行為"
                )
              }}
            </p>
          </li>

          <li>
            <h3>{{ $t("第31條 點數的無效・減少・取消") }}</h3>
            <p>
              {{
                $t(
                  "關於G18點數的使用，如果存在以下情況時將無事先通知地取消點數的有效性。另外，如果存在不當行為的嫌疑，將會在點數的使用、購買、交換時被限制。無效的點數將無法退還、交換。"
                )
              }}
            </p>
            <p>
              {{ $t("1、存在違法或不當行為時") }}
            </p>
            <p>
              {{ $t("2、違反本條款或其他G18所制定的條款、規定時") }}
            </p>
            <p>
              {{ $t("3、帳號已停止使用時") }}
            </p>
            <p>
              {{ $t("4、在G18所制定的方法外不當地獲得點數時") }}
            </p>
            <p>
              {{ $t("5、用戶已從G18退會時") }}
            </p>
            <p>
              {{ $t("6、其他G18認為應該取消用戶的點數時。") }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.TOS {
  padding-top: 46px;
  background-color: #F0F6F9;
  .topbar {
    display: flex;
    align-items: center;
    background-color: #F0F6F9;
    height: 46px;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    .v-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 24px;
    }
    .head-title {
      position: absolute;
      line-height: 1;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-size: 18px;
      color: #485573;
    }
  }
  .content {
    padding: 0 24px;
    ul {
      padding: 0;
      li {
        text-align: left;
        margin: 10px 0;

        h3 {
          font-size: 20px;
          font-weight: 700;
        }
        p {
          font-size: 16px;
          margin: 0;
        }
      }
    }
  }
}
</style>